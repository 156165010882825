import { configureStore } from "@reduxjs/toolkit";
import { queryService } from "../services/queryService.js";
import { chatService } from '../services/chatService.js';
import { loginService } from "../services/loginService.js";
import { adminManagementService } from "../services/adminManagementService.js";
import { usageAnalyticsService } from "../services/usageAnalyticsService.js";
import sessionReducer from "./slices/sessionSlice";
import chatHistoryReducer from "./slices/chatHistorySlice";
export const store = configureStore({
  reducer: {
    // Add the API reducer
    [loginService.reducerPath]: queryService.reducer,
    [queryService.reducerPath]: queryService.reducer,
    [chatService.reducerPath]: chatService.reducer,
    [adminManagementService.reducerPath]: adminManagementService.reducer,
    [usageAnalyticsService.reducerPath]: usageAnalyticsService.reducer,
    session: sessionReducer,
    chatHistory: chatHistoryReducer
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      queryService.middleware,
      loginService.middleware,
      chatService.middleware,
      adminManagementService.middleware,
      usageAnalyticsService.middleware
    ),
});
