
const UnauthorizedPage = () => {
  return (
    <div className="forbidden-container">
      <h1>401</h1>
      <h2>Unauthorized</h2>
      <p>You are not authorized to access this page.</p>
      <a href="/" className="forbidden-button">
        Go to Homepage
      </a>
    </div>
  );
};

export default UnauthorizedPage;