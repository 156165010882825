

const ForbiddenPage = () => {
  return (
    <div className="forbidden-container">
      <h1>403</h1>
      <h2>Forbidden</h2>
      <p>Access to this page is restricted.</p>
      <a href="/" className="forbidden-button">
        Go to Homepage
      </a>
    </div>
  );
};

export default ForbiddenPage;