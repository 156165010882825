import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CONFIG from "../../config";
import {
  ERROR_PAGE_401_ROUTE,
  ERROR_PAGE_500_ROUTE,
  PROJECT_SELECTION_ROUTE,
} from "../../constants/routeConstants";
function ADTokenPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const query_params = new URLSearchParams(location.search);
  const code = query_params.get("code");
  const client_info = query_params.get("client_info");
  const state = query_params.get("state");
  const session_state = query_params.get("session_state");
  const is_access_token_requested = useRef(false);

  window.history.replaceState(null, "", "/ad/token/");

  useEffect(() => {
    if (
      code == null ||
      client_info == null ||
      state == null ||
      session_state == null
    ) {
      navigate("/404");
      return;
    }
    async function getAccessToken() {
      if (is_access_token_requested.current === false) {
        const auth_response = {
          code: code,
          client_info: client_info,
          state: state,
          session_state: session_state,
        };

        const body = {
          auth_response: auth_response,
        };

        try {
          const response = await fetch(
            `${CONFIG.BASE_URL}/api/v1/login/ad/token`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );

          const resp_json = await response.json();

          if (resp_json.status === "success") {
            navigate(PROJECT_SELECTION_ROUTE);
          } else if (response.status === 401) {
            navigate(ERROR_PAGE_401_ROUTE);
          } else {
            navigate(ERROR_PAGE_500_ROUTE);
          }
        } catch (error) {
          navigate(ERROR_PAGE_500_ROUTE);
        }
      }
    }
    getAccessToken();
    is_access_token_requested.current = true;
  }, [code, client_info, state, session_state, navigate]);

  return <></>;
}

export default ADTokenPage;
