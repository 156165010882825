import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';  
import CONFIG from '../config.js';

const baseQuery = fetchBaseQuery({  
  baseUrl: CONFIG.BASE_URL, 
});  

export const adminManagementService = createApi({
    reducerPath: 'adminManagementServiceApi',  
    baseQuery,
    endpoints: (builder) => ({
        getUserList: builder.query({
            query: ({limit, offset}) => {
                const searchParams = new URLSearchParams();
                searchParams.append('limit', limit)
                searchParams.append('offset', offset)
                return {
                    url: `api/v1/users?${searchParams.toString()}`,
                    method: 'GET'
                }
                // return {
                //     url: `api/v1/chats`,
                //     method: 'GET'
                // }
            }
        }),
        getAllRoles: builder.query({
            query: ({limit, offset}) => {
                const searchParams = new URLSearchParams();
                searchParams.append('limit', limit)
                searchParams.append('offset', offset)
                return {
                    url: `api/v1/roles?${searchParams.toString()}`,
                    method: 'GET'
                }
            }
        }),
        editUser: builder.mutation({
            query: ({username, active, role_id}) => {
                console.log('editUser role_id', role_id)
                console.log('username', username)
                console.log('active', active)
                //return;
                const body = {active, role_id}
                return {
                    url: `api/v1/users/${username}`,
                    method: 'PATCH',
                    body
                }
            }
        }),
        addUser: builder.mutation({
            query: ({username, active, authentication_mode, role_id}) => {
                // console.log('addUser username', username)
                // console.log(active, authentication_mode, role_id)
                // return;
                const body = {username, active, authentication_mode, role_id};
                 return {
                    url: 'api/v1/users',
                    method: 'POST',
                    body
                }
            }
        }),
        deleteUser: builder.mutation({
            query: (username) => {
                // console.log('addUser username', username)
                // return;
                return {
                    url: `api/v1/users/${username}`,
                    method: 'DELETE'
                }
            }
        })
    })
})

export const {
    useGetUserListQuery,
    useGetAllRolesQuery,
    useEditUserMutation,
    useAddUserMutation,
    useDeleteUserMutation
} = adminManagementService;
