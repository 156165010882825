const ServerErrorPage = () => {
  return (
    <div className="servererror-container">
      <h1>500</h1>
      <h2>Internal Server Error</h2>
      <p>Sorry, something went wrong.</p>
      <a href="/" className="servererror-button">
        Go to Homepage
      </a>
    </div>
  );
};

export default ServerErrorPage;