import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, AppBar, Toolbar, Typography } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar.jsx";
import { useTheme } from "@emotion/react";
const Header = () => {
  const location = useLocation();
  const theme = useTheme();
  const getHeaderTitle = () => {
    const path = location.pathname;
    if (path.includes("/knowledge-base")) return "Knowledge Base";
    if (path.includes("/project-selection"))
      return "Client & Solution Selection";
    if(path.includes('/chat-history')) return 'Chat History';
    if(path.includes("/chat"))
      return "SOP Advisor"
    if(path.includes("/admin-management"))  return "Admin Management"
    if(path.includes("/usage-analytics"))  return "Usage Analytics"
    return "Dashboard"; // Default title
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        textAlign: "center",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {getHeaderTitle()}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const AppLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: "calc(100% - 300px)",
          backgroundColor: "#f7f8fa",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Box sx={{ flexGrow: 1, overflow: "auto", padding: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
