import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';  
import CONFIG from '../config.js';

const baseQuery = fetchBaseQuery({  
  baseUrl: CONFIG.BASE_URL, 
});  


export const usageAnalyticsService = createApi({
    reducerPath: 'usageAnalyticsServiceApi',
    baseQuery,
    endpoints: (builder) => ({
        getUserStatistics: builder.query({
            query: () => ({
                url: 'api/v1/analytics/user_statistics',
            })
        }),
        getQueryStatistics: builder.query({
            query: () => ({
                url: 'api/v1/analytics/query_statistics',
            })
        }),
        getQueryTurnAroundTime: builder.query({
            query: () => ({
                url: 'api/v1/analytics/query_turnaround_time',
            })
        }),
        getKnowledgeStatistics: builder.query({
            query: () => ({
                url: 'api/v1/analytics/knowledge_base_statistics',
            })
        }),
    })
})

export const {
    useGetUserStatisticsQuery,
    useGetQueryStatisticsQuery,
    useGetQueryTurnAroundTimeQuery,
    useGetKnowledgeStatisticsQuery
} = usageAnalyticsService;