import { drawerWidth } from './Sidebar';  
  
export const styles = theme => ({  
  drawer: {  
    width: drawerWidth.default,  
    maxWidth: '100%',  
    minWidth: drawerWidth.min,  
    [theme.breakpoints.down('sm')]: {  
        width: drawerWidth.min  
    },  
    [theme.breakpoints.up('lg')]: {  
        width: '20vw',  
        maxWidth: drawerWidth.max  
    },   
    flexShrink: 0,  
    [`& .MuiDrawer-paper`]: {   
        width: drawerWidth.default,  
        maxWidth: '100%',  
        minWidth: drawerWidth.min,  
        [theme.breakpoints.down('sm')]: {  
            width: drawerWidth.min  
        },  
        [theme.breakpoints.up('lg')]: {  
            width: '20vw',  
            maxWidth: drawerWidth.max,  
            boxSizing: 'content-box'  
        },   
        boxSizing: 'border-box'   
    },  
  },  
});  
