import { createSlice } from '@reduxjs/toolkit';  
 
const initialState = {  
  session_id: '',  
  questionGroup: [],  
  document_id: null,
  session_started: false
};  
 
const sessionSlice = createSlice({  
  name: 'session',  
  initialState,
  reducers: {  
    setSessionId: (state, action) => {  
      state.session_id  = action.payload
    },  
    setSessionStarted: (state, action) => {
      state.session_started = action.payload;
    },
    setDocumentId: (state, action) => {
      state.document_id = action.payload
    },
    updateQueryId: (state, action) => {  
      const { tempId, newId } = action.payload;  
      const question = state.questionGroup.find(q => q.question.query_id === tempId);  
      if (question) {  
        question.question.query_id = newId;  
      }  
    },
    addQuestion: (state, action) => {  
      state.questionGroup.push({
        question: action.payload,
        responseData: {},
        loading: true,
        error: ''
      });  
    },
    setQuestionData: (state, action) => {
      const { id, data } = action.payload;  
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question) {  
        question.question = data;  
        question.loading = false;
      }
    } ,  
    setResponseData: (state, action) => {  
      const { id, data } = action.payload;  
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question) {  
        question.responseData = data;  
      }  
    },
    updateLike: (state, action) => {  
      const { id, Like } = action.payload;  
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question && question.responseData) {  
        question.responseData.Like = Like;  
      }  
    },
    updateQuestion: (state, action) => {
      const { id, query } = action.payload;  
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question && question.question) {  
        question.question.Query = query;  
      }  
    },
    updateLoading: (state, action) => {
      const {id, val} = action.payload;
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question && question.question) {  
        question.loading = val;  
      }  
    },
    updateError: (state, action) => {
      const {id, val} = action.payload;
      const question = state.questionGroup.find(q => q.question.query_id === id);  
      if (question && question.question) {  
        question.error = val;  
      }  
    },
    replaceQuestionGroup : (state, action) => {
      state.questionGroup = action.payload;
    },
    resetSession: () => initialState
  },  
});  
 
export const selectIsLoading = state => state.session.questionGroup.some(group => group.loading);  
 
export const {
  addQuestion,
  updateQueryId,
  setQuestionData,
  setResponseData,
  setSessionId,
  updateLike,
  updateQuestion,
  updateLoading,
  updateError,
  replaceQuestionGroup,
  setDocumentId ,
  setSessionStarted,
  resetSession
} = sessionSlice.actions;  
export default sessionSlice.reducer;