import React, { useState, useRef } from "react";
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  IconButton,
  Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { ReactComponent as ChatWindowPageIcon } from "../../assets/images/chat-window-page-icon.svg";
import { ReactComponent as KnowledgeBasePageIcon } from "../../assets/images/knowledge-base-page-icon.svg";
import { ReactComponent as ChatHistoryPageIcon } from "../../assets/images/chat-history-page-icon.svg";
import { ReactComponent as ChatHistoryLinkIcon } from '../../assets/images/clock.svg';
import { ReactComponent as AdminManagementLinkIcon } from '../../assets/images/user-pen.svg';
import { ReactComponent as UsageAnalyticsLinkIcon } from '../../assets/images/user-cog.svg';
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { Memory as CpuIcon, Chat as ChatIcon, Book as BookIcon } from "@mui/icons-material";

import {
  CHAT_PAGE_ROUTE,
  KNOWLEDGE_BASE_ROUTE,
  LOGIN_PAGE_ROUTE,
  PROJECT_SELECTION_ROUTE,
  CHAT_HISTORY_PAGE_ROUTE,
  ADMIN_MANAGEMENT_PAGE_ROUTE,
  USAGE_ANALYTICS_PAGE_ROUTE
} from "../../constants/routeConstants";
import { AccountCircle, ExitToApp, Settings as SettingsIcon  } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CONFIG from "../../config";

import { styles } from "./styles";

const baseUrl = CONFIG.BASE_URL;
export const drawerWidth = { default: 250, min: 200, max: 300 };

export default function Sidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [transitionComplete, setTransitionComplete] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [settingsopen, setSettingsOpen] = useState(false);
  const navigate = useNavigate();
  const {
    palette: { sop },
    spacing,
  } = theme;
  const anchorRef = useRef(null);

  const navigationItems = [
    {
      text: "Project Selection",
      path: PROJECT_SELECTION_ROUTE,
      icon: (isActive) => (
        <CpuIcon
          sx={{
            color: isActive ? theme.palette.secondary.main : sop.white,
            fontSize: 30,
          }}
        />
      ),
    },

    {
      text: "Chat Window",
      path: CHAT_PAGE_ROUTE,
      icon: (isActive) => (
        <ChatIcon
          sx={{
            color: isActive ? theme.palette.secondary.main : sop.white,
            fontSize: 30,
          }}
        />
      ),
    },

    {
      text: "Knowledge Base",
      path: KNOWLEDGE_BASE_ROUTE,
      icon: (isActive) => (
        <BookIcon
          sx={{
            color: isActive ? theme.palette.secondary.main : sop.white,
            fontSize: 30,
          }}
        />
      ),
    },
    {
      text: "Chat History",
      path:   CHAT_HISTORY_PAGE_ROUTE,
      icon: (isActive) => (
        <ChatHistoryLinkIcon
          stroke={isActive ? theme.palette.secondary.main : sop.white}
        />
      ),
    },
    // {
    //   text: "Admin Management",
    //   path:   ADMIN_MANAGEMENT_PAGE_ROUTE,
    //   icon: (isActive) => (
    //     <AdminManagementLinkIcon
    //       stroke={isActive ? theme.palette.secondary.main : sop.white}
    //     />
    //   ),
    // },
    // {
    //   text: "Usage Analytics",
    //   path:   USAGE_ANALYTICS_PAGE_ROUTE,
    //   icon: (isActive) => (
    //     <UsageAnalyticsLinkIcon
    //       stroke={isActive ? theme.palette.secondary.main : sop.white}
    //     />
    //   ),
    // }
  ];

  const handleDrawerToggle = () => {
    setOpen(!open);
    setTransitionComplete(false);
  };

  const handleTransitionEnd = () => {
    setTransitionComplete(true);
  };

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const handleToggle = () => {
    setSettingsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSettingsOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSettingsOpen(false);
    } else if (event.key === 'Escape') {
      setSettingsOpen(false);
    }
  }

  const handleLogoutConfirm = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      if (response.status === 200) {
        navigate(LOGIN_PAGE_ROUTE);
        setLogoutDialogOpen(false);
      }
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle logout error (show a message to the user, etc.)
    } finally {
      setLogoutDialogOpen(false);
    }
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      onTransitionEnd={handleTransitionEnd}
      sx={{
        width: open ? 250 : 70,
        zIndex: 99,
        transition: "width 0.3s",
        "& .MuiDrawer-paper": {
          width: open ? 250 : 70,
          transition: "width 0.3s",
          backgroundColor: theme.palette.primary.main,
          overflowX: "hidden",
          position: 'static',
        },
      }}
    >
      <IconButton
        sx={{
          color: sop.white,
          margin: spacing(2.5),
        }}
        disableRipple
        onClick={handleDrawerToggle}
      >
        {open && transitionComplete && (
          <Typography variant="h4" sx={{ color: theme.palette.secondary.main, mr: 2.5 }}>
            SOP DASHBOARD
          </Typography>
        )}
        <MenuIcon stroke={theme.palette.secondary.main}/>
      </IconButton>
      <List sx={{p: 2}}>
        {navigationItems.map((item, index) => {
          const isActive = location.pathname === item.path;
          const isHovered = hoveredItem === index;
          
          return (
            <ListItem
              button
              component={Link}
              to={item.path}
              selected={isActive}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
              sx={{
                backgroundColor: "transparent",
                color: isActive ? theme.palette.secondary.main : sop.white,
                cursor: "pointer",
                borderRadius: `${theme.shape.borderRadius}px`,
                marginBottom: 1,
                border: `1px solid ${
                  isActive || isHovered ? theme.palette.secondary.main : "transparent"
                }`,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <ListItemIcon>
                {item.icon(isActive || isHovered)}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  display: open ? "block" : "none",
                  "& .MuiTypography-root": {
                    color: isActive || isHovered ? theme.palette.secondary.main : "inherit",
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>

      <Box sx={{ marginTop: "auto", padding: 2 }}>
        {[
          { text: "Account Settings", icon: SettingsIcon, path: [ADMIN_MANAGEMENT_PAGE_ROUTE, USAGE_ANALYTICS_PAGE_ROUTE], onClick: handleToggle,  ref: anchorRef},
          // { text: "User Profile", icon: AccountCircle, onClick: () => {} },
          { text: "Logout", icon: ExitToApp, onClick: handleLogout },
        ].map((item, index) => {
          const isActive =  item?.path && item.path.includes(location.pathname);
          const isHovered = hoveredItem === `bottom-${index}`;
          let adminActive, analyticsActive;
          // if(isActive) {
            if (location.pathname === '/admin-management') {
              adminActive = true;
            }
            if (location.pathname === '/usage-analytics') {
              analyticsActive = true;
            }
          // }
          console.log('adminActive', adminActive)
          return (
            <>
            <ListItem
              key={item.text}
              button
              {...(item.ref ? { ref: item.ref } : {})}
              // component={Link}
              // to={item.path}
              onClick={item.onClick}
              onMouseEnter={() => setHoveredItem(`bottom-${index}`)}
              onMouseLeave={() => setHoveredItem(null)}
              sx={{
                backgroundColor: "transparent",
                color: sop.white,
                cursor: "pointer",
                borderRadius: `${theme.shape.borderRadius}px`,
                marginBottom: 1,
                color: isActive ? theme.palette.secondary.main : sop.white,
                border: `1px solid ${
                  isHovered ? theme.palette.secondary.main : "transparent"
                }`,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <ListItemIcon>
                <item.icon
                  sx={{
                    color: isActive || isHovered ? theme.palette.secondary.main : sop.white,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  display: open ? "block" : "none",
                  "& .MuiTypography-root": {
                    color: isHovered ? theme.palette.secondary.main : "inherit",
                  },
                }}
              />
            </ListItem>
            <Popper
              open={settingsopen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="right"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={settingsopen}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem 
                            component={Link}
                            to='/admin-management'
                            // onClick={handleClose}
                            sx={{
                              color: adminActive ? theme.palette.secondary.main : sop.black,
                              backgroundColor: adminActive ? sop.black : sop.white,
                              cursor: "pointer",
                              borderRadius: `${theme.shape.borderRadius}px`,
                              marginBottom: 1,
                              border: `1px solid ${
                                adminActive || isHovered ? theme.palette.secondary.main : sop.white
                              }`,
                              "&:hover": {
                                color: theme.palette.secondary.main,
                                backgroundColor: sop.black,
                              },
                              '& svg' : {
                                stroke: adminActive ? theme.palette.secondary.main : sop.black,
                              },
                              '&:hover svg': {
                                stroke: theme.palette.secondary.main,  
                              },
                              '&:hover p': {
                                color: theme.palette.secondary.main,
                              }
                            }}
                  
                        >
                          <>
                          <Box
                            component="span"
                            sx={{
                              mr: 2,
                            }}
                          >
                             <AdminManagementLinkIcon 
                            />
                          </Box>
                         
                          <Typography
                          >
                            Admin Management
                          </Typography>
                          </>
                        </MenuItem>
                        <MenuItem 
                          component={Link}
                          to='/usage-analytics'
                          sx={{
                            color: analyticsActive ? theme.palette.secondary.main : sop.black,
                            backgroundColor: analyticsActive ? sop.black : sop.white,
                            cursor: "pointer",
                            borderRadius: `${theme.shape.borderRadius}px`,
                            marginBottom: 1,
                            border: `1px solid ${
                              analyticsActive || isHovered ? theme.palette.secondary.main : sop.white
                            }`,
                            "&:hover": {
                                color: theme.palette.secondary.main,
                                backgroundColor: sop.black,
                            },
                            '& svg' : {
                                stroke: analyticsActive ? theme.palette.secondary.main : sop.black,
                            },
                            '&:hover svg': {
                              stroke: theme.palette.secondary.main,  
                            },
                            '&:hover p': {
                              color: theme.palette.secondary.main,
                            }
                          }}
                        >
                          <>
                          <Box
                            component="span"
                            sx={{
                              mr: 2,
                            }}
                          >
                             <UsageAnalyticsLinkIcon 
                            />
                          </Box>
                         
                          <Typography
                            // sx={{
                            //   color: analyticsActive ? theme.palette.secondary.main : sop.black,
                            // }}
                          >
                            Usage Analytics
                          </Typography>  
                          </>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            </>
          );
        })}
      </Box>

      <Dialog open={logoutDialogOpen} onClose={handleLogoutCancel}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel}>Cancel</Button>
          <Button onClick={handleLogoutConfirm} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}
