import { createSlice } from '@reduxjs/toolkit';  
 
const initialState = {  
  data: {},  
  count: 0,
  offset: 0
};  

const chatHistorySlice = createSlice({ 
    name: 'chatHistory',  
    initialState,
    reducers: {
       addChatHistory: (state, action) => {
            const {history} = action.payload;
            console.log('history', history)
            const keys = Object.keys(history)
            console.log('keys', keys)
            keys.forEach(key => {
                if (state.data[key]) {
                    state.data[key] = [...state.data[key], ...history[key]];
                } else {
                    state.data[key] = history[key];
                }
            })
       },
       resetChatHistory: () => initialState,
       setOffset: (state, action) => {
            state.offset = action.payload
       }
    }
 })

 export const {addChatHistory, resetChatHistory, setOffset} = chatHistorySlice.actions;

 export default chatHistorySlice.reducer;