import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';  
import CONFIG from '../config.js';

const baseQuery = fetchBaseQuery({  
  baseUrl: CONFIG.BASE_URL, 
});  

export const chatService = createApi({
    reducerPath: 'chatServiceApi',  
    baseQuery,
    endpoints: (builder) => ({
        getUserChats: builder.query({
            query: ({limit, offset}) => {
                const searchParams = new URLSearchParams();
                searchParams.append('limit', limit)
                searchParams.append('offset', offset)
                return {
                    url: `api/v1/chats?${searchParams.toString()}`,
                    method: 'GET'
                }
                // return {
                //     url: `api/v1/chats`,
                //     method: 'GET'
                // }
            }
        }),
        getChatsBySession: builder.query({
            query: (chat_id) => ({
                url: `api/v1/chats/${chat_id}`,
                method: 'GET'
            })
        })
    })
})

export const {
    useGetUserChatsQuery,
    useGetChatsBySessionQuery
} = chatService;
