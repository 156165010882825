import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';  
import CONFIG from '../config.js';

const baseQuery = fetchBaseQuery({  
  baseUrl: CONFIG.BASE_URL, 
});  
  
export const queryService = createApi({  
  reducerPath: 'queryServiceApi',  
  baseQuery,  
  endpoints: (builder) => ({  
    // Define your endpoints here  
    getQueryResponse: builder.mutation({  
      query: ({chat_session_id, query, signal}) => ({
        url: 'api/v1/query',
        method: 'POST',
        body: {chat_session_id, query},
        signal
      })
    }),  
    getFile: builder.query({
      query: (document_id) => ({
        url: `api/v1/knowledge-base/${document_id}?mode=download`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response, meta) => {  
        // Return a serializable object containing the blob and content-type  
        return {  
          blob: response,  
          contentType: meta.response.headers.get('content-type'),  
        };  
      },  
    })
  }),  
});  
  
export const { useGetQueryResponseMutation, useLazyGetFileQuery  } = queryService; 

