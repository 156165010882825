export const LOGIN_PAGE_ROUTE = "/login";
export const AD_TOKEN_PAGE_ROUTE = "/ad/token/";
export const ERROR_PAGE_401_ROUTE = "/401";
export const ERROR_PAGE_403_ROUTE = "/403";
export const ERROR_PAGE_404_ROUTE = "/404";
export const ERROR_PAGE_500_ROUTE = "/500";
export const CHAT_PAGE_ROUTE = "/chat";
export const PROJECT_SELECTION_ROUTE = "/landing";
export const KNOWLEDGE_BASE_ROUTE = "/knowledge-base";
export const CHAT_HISTORY_PAGE_ROUTE = '/chat-history';
export const ADMIN_MANAGEMENT_PAGE_ROUTE = '/admin-management';
export const USAGE_ANALYTICS_PAGE_ROUTE = '/usage-analytics';
