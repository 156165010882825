import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import CONFIG from "../config.js";

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.BASE_URL,
  credentials: "include", // This is important for handling cookies if your API uses them for authentication
});

export const loginService = createApi({
  reducerPath: "loginServiceApi",
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (params) => ({
        url: "api/v1/login/ad/auth-url",
        method: "GET",
        params: params,
      }),
    }),
    getAccessToken: builder.mutation({
      query: (credentials) => ({
        url: "api/v1/login/ad/token",
        method: "POST",
        body: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
    checkAuthStatus: builder.query({
      query: () => "auth/status",
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useCheckAuthStatusQuery } =
  loginService;
