import { createTheme } from "@mui/material/styles";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
}).breakpoints;

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: "#2E2E38",
    },
    secondary: {
      main: "#FFE600",
    },
    sop: {
      darkBlue: "#1D2735",
      blue800: "#007EF2",
      gray: "#132A24",
      tableGray: "#e4e6e6",
      black: "#000000",
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: "EYInterstate, Arial, sans-serif",
    h3: {
      fontSize: "25px",
      fontWeight: "500",
      fontStyle: "bold",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "600",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "600",
    },
    h6: {
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "bold",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: "500",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",
    },
  },
  spacing: 4,
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 17,
          height: 17,
          "&:not(:last-child):not(.MuiSelect-icon)": {
            marginRight: "20px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#EBF3FF",
          color: "#000",
          height: "45px",
          // padding: '10px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#EBF3FF",
            color: "#000",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
